<template>
  <div id="cms-schedules">
    <b-container fluid>
      <b-row>
        <b-col></b-col>
        <b-col><h2 style="color: white; margin-bottom: 1em">Schedule</h2></b-col>
        <b-col>
          <router-link class="link-color" :to="{ name: 'CmsScheduleCreate' }" @click.prevent>
            <b-button type="submit" variant="success">Add Schedule</b-button>
          </router-link>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <SideMenu></SideMenu>
        </b-col>
        <b-col class="col-sm-9 work-schedule">
          <div class="calendar">
            <b-calendar
                    @context="onContext"
                    :locale="locale"
                    :start-weekday="weekday"
                    :hide-header="hideHeader"
            >

            </b-calendar>
          </div>
          <div v-if="loading" class="text-center">
            <b-spinner variant="secondary" label="Loading"></b-spinner>
          </div>
          <div v-else class="context">
            <b-card-group deck>
              <b-card
                      v-for="schedule in scheduleResponse"
                      :key="schedule.id"
                      :bg-variant="'info'"
                      text-variant="white"
                      :header="schedule.employee.name"
                      class="text-center">
                <b-card-text>time: {{ formatTime(schedule.start_time)
                  + ' - '
                  + formatTime(schedule.end_time)
                  + ' (' + schedule.hours.toFixed(2)
                  + ' hours)' }}</b-card-text>
              </b-card>
            </b-card-group>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import SideMenu from '@/components/SideMenu.vue'

  export default {
    data() {
      return {
        loading: true,
        scheduleResponse: null,
        selectedDate: null,
        locale: 'en-NL',
        weekday: 1,
        hideHeader: true
      }
    },
    components: {
      SideMenu
    },

    methods: {
      getSelectedDate(context) {
        if (!context.selectedYMD) {
          return context.activeYMD
        }

        return context.selectedYMD
      },

      onContext(context) {
        this.loading = true

        this.axios
            .get('https://batavia-backend.herokuapp.com/api/work-schedule', {
              params: {
                date: this.getSelectedDate(context)
              }
            })
            .then(response => (
                this.scheduleResponse = response.data
            ))
            .finally(() => {
              this.loading = false
            })
      },

      formatTime(time) {
        return time.slice(0, -3)
      }
    }
  }
</script>

<style scoped>
  .work-schedule {
    overflow: auto;
    height: 400px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
</style>
